//////////////////////// DEPENDENCIES ////////////////////////

import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';

import { useTools } from '../../hooks/useTools';

import { ExportToCsv } from 'export-to-csv';
import DocumentDefinition from '../../utils/PDFDocumentDefinition';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf',
  },
};

const log = false;

//////////////////////// COMPONENT ////////////////////////

export function useInvoices() {
  const { round, clampNegNum, getMonetaryValue, getFormattedCancelStatusFromDrive, getFormattedStatusFromDrive } =
    useTools();

  // Get local storage items
  const getDefaultInvoiceId = () => {
    const localInvoiceId = localStorage.getItem(`invoice-id`);
    if (localInvoiceId) return parseInt(localInvoiceId);
    return null;
  };
  const getDefaultARMoveId = () => {
    const localARMoveId = localStorage.getItem(`invoice-armove-id`);
    if (localARMoveId) return parseInt(localARMoveId);
    return null;
  };
  const getDefaultFoldId = () => {
    const localFoldId = localStorage.getItem(`invoice-fold-id`);
    if (localFoldId) return parseInt(localFoldId);
    return null;
  };
  const getDefaultCustomerId = () => {
    const localCustomerId = localStorage.getItem(`invoice-customer-id`);
    if (localCustomerId) return parseInt(localCustomerId);
    return null;
  };
  const getDefaultStart = () => {
    const localStart = localStorage.getItem(`invoice-list-start`);
    if (localStart) return dayjs.utc(dayjs(localStart)).format();
    return dayjs.utc(dayjs().startOf(`day`).subtract(1, `month`)).format();
  };
  const getDefaultEnd = () => {
    const localEnd = localStorage.getItem(`invoice-list-end`);
    if (localEnd) return dayjs.utc(dayjs(localEnd)).format();
    return dayjs.utc(dayjs().endOf(`day`)).format();
  };

  /** Get total mileage from an array of moves */
  const getTotalMileageFromMoves = (moves = []) => {
    // Default fallback when not enough info is provided
    const fallbackMileage = 0;

    // Check for moves
    if (moves && moves.length > 0) {
      // Set local variables
      let mileages = [];
      let mileage = fallbackMileage;

      // Map through the moves and get a list of distance_miles from the lane object
      mileages = moves.map(move => (getPropValue(move, `lane.distance_miles`) ? move.lane.distance_miles : 0));
      mileage = mileages.reduce((total, current) => total + current);

      return mileage;
    }
    return fallbackMileage;
  };

  /** Get paid total from an array of arpayments */
  const getPaidTotalFromARPayments = (payments = []) => {
    // Default fallback when not enough info is provided
    const fallbackPaidTotal = 0;

    // Check for payments
    if (payments && payments.length > 0) {
      // Set local variables
      let paidPayments = [];
      let paidTotal = fallbackPaidTotal;

      // Map through the payments and get a list of paid payments based on status
      paidPayments = payments.filter(p => p.status === `paid` || p.status === `successful`).map(p => p.amount);
      paidTotal = paidPayments.length > 0 ? paidPayments.reduce((total, current) => total + current) : 0;

      return paidTotal;
    }
    return fallbackPaidTotal;
  };

  /** Build an object that holds the calculations of an invoice */
  const buildAmountObject = (invoice = { armoves: [], arpayments: [] }) => {
    // Set default amount object
    let amount = {};

    // Check for invoice
    if (invoice) {
      // Set armoves
      const armoves = invoice.armoves;
      // const armovesWithoutPrepaid = invoice.armoves.filter(armove => armove.type !== `prepaid`);
      const arpayments = invoice.arpayments.filter(arp => arp.status === `paid` || arp.status === `successful`);

      const prepaidArmoves = invoice.armoves.filter(armove => armove.type === `prepaid`);
      //Only count paid prepaid packages
      const prepaidAmount = prepaidArmoves
        .map(armove => armove.paid_amount)
        .reduce((total, current) => total + current, 0);
      log && console.log(prepaidArmoves, 'prepaidArmoves', prepaidAmount);

      const processingFeeArray = invoice.armoves.filter(armove => armove.type === `fee`) || [];
      var processingFeeSum = 0;
      if (processingFeeArray.length > 0) {
        const processingFeeAmounts = processingFeeArray.map(pf => pf.paid_amount);
        processingFeeSum = processingFeeAmounts.reduce((total, current) => total + current, 0);
      }

      // Filter valid records for each type
      const subtotalMoves = armoves.filter(item => item.due_amount > 0);
      const discountedMoves = subtotalMoves.filter(
        item => item.disputed === false && item.due_amount >= item.discount_amount && item.discount_amount > 0
      );
      const disputedMoves = subtotalMoves.filter(item => item.disputed === true);
      const moveTypeArMoves = subtotalMoves.filter(item => item.type === 'move');
      const spoilageTypeArmoves = subtotalMoves.filter(item => item.type === 'spoilage');
      const paidMoves = subtotalMoves.filter(item => item.paid_amount > 0 && item.type !== 'prepaid');
      const paidAmounts = paidMoves.map(move => move.paid_amount);
      log && console.log('paidMoves', paidMoves, paidAmounts);
      const productArmoves = subtotalMoves.filter(
        item => item.type === 'product' || item.type === 'one-time' || item.type === 'prepaid'
      );

      // Set base totals from filtered valid records
      amount.subtotal = round(
        subtotalMoves.length > 0
          ? subtotalMoves.map(item => item.due_amount).reduce((total, current) => total + current)
          : 0,
        2
      );
      amount.moveDueTotal = round(
        moveTypeArMoves.length > 0
          ? moveTypeArMoves.map(item => item.due_amount).reduce((total, current) => total + current)
          : 0,
        2
      );
      amount.spoilageDueTotal = round(
        spoilageTypeArmoves.length > 0
          ? spoilageTypeArmoves.map(item => item.due_amount).reduce((total, current) => total + current)
          : 0,
        2
      );
      amount.discounted = round(
        discountedMoves.length > 0
          ? discountedMoves.map(item => item.discount_amount).reduce((total, current) => total + current)
          : 0,
        2
      );
      amount.disputed = round(
        disputedMoves.length > 0
          ? disputedMoves.map(item => item.due_amount).reduce((total, current) => total + current)
          : 0,
        2
      );
      amount.paid = round(
        paidMoves.length > 0 ? paidMoves.map(item => item.paid_amount).reduce((total, current) => total + current) : 0,
        2
      );
      amount.paymentAmount = round(
        //add up all the arpayment totals
        arpayments.length > 0 ? arpayments.map(item => item.amount).reduce((total, current) => total + current) : 0,
        2
      );
      amount.totalWithoutPrepaid = round(
        armoves.length > 0 ? armoves.map(item => item.due_amount).reduce((total, current) => total + current) : 0,
        2
      );
      amount.productTotal = round(
        productArmoves.length > 0
          ? productArmoves.map(item => item.due_amount).reduce((total, current) => total + current)
          : 0,
        2
      );

      // Set usable totals from base totals
      amount.prepaid = clampNegNum(prepaidAmount);
      amount.total = clampNegNum(amount.subtotal - amount.discounted - amount.disputed);
      amount.totalSansProcessingFee = clampNegNum(
        amount.subtotal - amount.discounted - amount.disputed - processingFeeSum
      );
      amount.due = clampNegNum(amount.total - (amount.paymentAmount));
      amount.balanceRemaining = prepaidAmount - (amount.moveDueTotal + amount.spoilageDueTotal);
      amount.processingFee = clampNegNum(processingFeeSum);

      log && console.log('AMOUNT OBJ', amount);

      // Set boolean to check if the invoice can be paid for
      amount.payable = amount.due > 0 && invoice.status === `closed`;
    }

    return amount;
  };

  const formatWeightClass = inputString => {
    if (!inputString) return '-';
    let formattedString = inputString.replace(/-/g, ' ');
    formattedString = formattedString.replace(/\s+\S+$/, '');
    formattedString = formattedString.replace(/^\w/, firstWord => firstWord.toUpperCase());
    return formattedString;
  };

  /** Generate an invoice CSV list for the user to download */
  const generateCSV = (invoice = null) => {
    if (invoice) {
      try {
        // Create rows and options for CSV
        const createCsvRow = armove => {
          const transportDetail = armove.details.find(detail => detail.name === `Transport`);
          return {
            MOVE_ID: armove.move.id,
            REF_NUM: armove.move.reference_num ? armove.move.reference_num : `-`,
            VIN: armove.move.vehicle_vin ? armove.move.vehicle_vin : `-`,
            MOVE_DATE: dayjs.utc(dayjs(armove.billable_datetime)).format(`MM/DD/YYYY`),
            STOCK: armove.move.vehicle_stock ? armove.move.vehicle_stock.toUpperCase() : 'N/A',
            LANE: armove.move.lane && armove.move.lane.description ? armove.move.lane.description : `Unknown Lane`,
            DISTANCE:
              armove.move.lane && armove.move.lane.distance_miles ? `${armove.move.lane.distance_miles} mi` : `-`,
            WEIGHT_CLASS:
              transportDetail && transportDetail.amount > 0 && transportDetail.rate_source
                ? formatWeightClass(transportDetail.rate_source)
                : `-`,
            COST: armove.due_amount
              ? `${getMonetaryValue(clampNegNum(armove.due_amount - armove.discount_amount))}`
              : armove.due_amount === 0
              ? getMonetaryValue(0)
              : `-`,
            CANCEL_STATUS: armove.move.cancel_status ? `${getFormattedCancelStatusFromDrive(armove.move)}` : `-`,
            MOVE_STATUS: armove.move.status ? `${getFormattedStatusFromDrive(armove.move)}` : `-`,
            DISTANCE_MILES:
              armove.move.lane && armove.move.lane.distance_miles ? armove.move.lane.distance_miles : null,
          };
        };
        const csvRows = invoice.armoves.filter(arm => arm.type === `move`).map(armove => createCsvRow(armove));
        const csvOptions = {
          filename: `${invoice.customer.name.replace(/ /g, '_')}_Invoice_${dayjs(invoice.start_datetime)
            .add(1, `day`)
            .format('MM-DD-YYYY')}_to_${dayjs(invoice.end_datetime).format('MM-DD-YYYY')}`,
          showTitle: true,
          title: `Invoice #${invoice.id}`,
          useKeysAsHeaders: true,
        };

        // Create and generate the CSV
        const csvExporter = new ExportToCsv(csvOptions);
        csvExporter.generateCsv(csvRows);
        toast.info(`Generated CSV!`, { autoClose: 2000 });
      } catch (err) {
        toast.error(`Failed to generate CSV!`);
        console.error(`Failed to generate CSV:`, err);
      }
    }
  };

  /** Generate an invoice CSV list for the user to download */
  const generateEnhancedCSV = (invoice = null) => {
    if (invoice) {
      try {
        // Create rows and options for CSV
        const createCsvRow = armove => {
          const transportDetail = armove.details.find(detail => detail.name === `Transport`);
          return {
            ROOFTOP: getPropValue(armove, 'move.customer.name') || `-`, //For "multi-rooftop" customers
            INVOICE_ID: armove.invoice_id || `-`,
            MOVE_ID: armove.move.id,
            REF_NUM: armove.move.reference_num ? armove.move.reference_num : `-`,
            VIN: armove.move.vehicle_vin ? armove.move.vehicle_vin : `-`,
            MOVE_DATE: dayjs.utc(dayjs(armove.billable_datetime)).format(`MM/DD/YYYY`),
            STOCK: armove.move.vehicle_stock ? armove.move.vehicle_stock.toUpperCase() : 'N/A',
            LANE: armove.move.lane && armove.move.lane.description ? armove.move.lane.description : `Unknown Lane`,
            DISTANCE:
              armove.move.lane && armove.move.lane.distance_miles ? `${armove.move.lane.distance_miles} mi` : `-`,
            WEIGHT_CLASS:
              transportDetail && transportDetail.amount > 0 && transportDetail.rate_source
                ? formatWeightClass(transportDetail.rate_source)
                : `-`,
            COST: armove.due_amount
              ? `${getMonetaryValue(clampNegNum(armove.due_amount - armove.discount_amount))}`
              : armove.due_amount === 0
              ? getMonetaryValue(0)
              : `-`,
            TRANSPORT_TOTAL: getPropValue(armove, 'transportDetails.aggregate.sum.amount') || 0,
            FUEL_TOTAL: getPropValue(armove, 'fuelDetails.aggregate.sum.amount') || 0,
            FUEL_COUNT: getPropValue(armove, 'fuelDetails.aggregate.count') || 0,
            SURGE_TOTAL: getPropValue(armove, 'surgeDetails.aggregate.sum.amount') || 0,
            SURGE_COUNT: getPropValue(armove, 'surgeDetails.aggregate.count') || 0,
            PARTIAL_TOTAL: getPropValue(armove, 'partialDetails.aggregate.sum.amount') || 0,
            PARTIAL_COUNT: getPropValue(armove, 'partialDetails.aggregate.count') || 0,
            WAIVED_TOTAL: getPropValue(armove, 'waivedDetails.aggregate.sum.amount') || 0,
            WAIVED_COUNT: getPropValue(armove, 'waivedDetails.aggregate.count') || 0,
            TOW_TOTAL: getPropValue(armove, 'towDetails.aggregate.sum.amount') || 0,
            TOW_COUNT: getPropValue(armove, 'towDetails.aggregate.count') || 0,
            DELAY_TOTAL: getPropValue(armove, 'delayDetails.aggregate.sum.amount') || 0,
            DELAY_COUNT: getPropValue(armove, 'delayDetails.aggregate.count') || 0,
            RIDE_TOTAL: getPropValue(armove, 'rideDetails.aggregate.sum.amount') || 0,
            RIDE_COUNT: getPropValue(armove, 'rideDetails.aggregate.count') || 0,
            CANCEL_STATUS: armove.move.cancel_status ? `${getFormattedCancelStatusFromDrive(armove.move)}` : `-`,
            MOVE_STATUS: armove.move.status ? `${getFormattedStatusFromDrive(armove.move)}` : `-`,
            DISTANCE_MILES:
              armove.move.lane && armove.move.lane.distance_miles ? armove.move.lane.distance_miles : null,
          };
        };
        const csvRows = invoice.armoves.filter(arm => arm.type === `move`).map(armove => createCsvRow(armove));
        const csvOptions = {
          filename: `${invoice.customer.name.replace(/ /g, '_')}_Invoice_${dayjs(invoice.start_datetime)
            .add(1, `day`)
            .format('MM-DD-YYYY')}_to_${dayjs(invoice.end_datetime).format('MM-DD-YYYY')}`,
          showTitle: true,
          title: `Invoice #${invoice.id}`,
          useKeysAsHeaders: true,
        };

        // Create and generate the CSV
        const csvExporter = new ExportToCsv(csvOptions);
        csvExporter.generateCsv(csvRows);
        toast.info(`Generated CSV!`, { autoClose: 2000 });
      } catch (err) {
        toast.error(`Failed to generate CSV!`);
        console.error(`Failed to generate CSV:`, err);
      }
    }
  };

  /** Generate an invoice PDF list for the user to download */
  const generatePDF = async (invoice = null, contacts = {}) => {
    if (invoice) {
      const amount = buildAmountObject(invoice);

      try {
        var docDefinition = new DocumentDefinition(invoice, amount, contacts);
        let dd = docDefinition.create(invoice, amount, contacts);
        let name = 'invoice.pdf';
        name = `${invoice.customer.name.replace(/ /g, '_')}_Invoice_${dayjs(invoice.start_datetime)
          .add(1, `day`)
          .format('MM-DD-YYYY')}_to_${dayjs(invoice.end_datetime).format('MM-DD-YYYY')}.pdf`;
        pdfMake.createPdf(dd).download(name);
        toast.info(`Generated PDF!`, { autoClose: 2000 });
      } catch (err) {
        toast.error(`Failed to generate PDF!`);
        console.error(`Failed to generate PDF:`, err);
      }
    }
  };

  // Return additional logic
  return {
    getDefaultInvoiceId,
    getDefaultARMoveId,
    getDefaultFoldId,
    getDefaultCustomerId,
    getDefaultStart,
    getDefaultEnd,
    getTotalMileageFromMoves,
    getPaidTotalFromARPayments,
    buildAmountObject,
    generateCSV,
    generateEnhancedCSV,
    generatePDF,
  };
}
